<template>
  <div style="background: rgb(7 27 28)" class="my-16 py-16 px-8 text-center white--text">
    <h2 class="mb-4 mt-8" style="font-size: 2em; font-weight: 400"><span style="font-weight: 200">{{$t('prices.benefits.header')}}</span> {{$t('prices.benefits.header_bold')}}</h2>
    <hr style="width: 100px" class="mx-auto mb-16">
    <v-row style="max-width: 963px" class="mx-auto mb-8">
      <v-col>
        <h2 class="mb-2" style="font-weight: 400">{{$t('prices.benefits.1.header')}}</h2>
        <p class="body-2" style="font-weight: 300">{{$t('prices.benefits.1.text')}}</p>
      </v-col>
      <v-col>
        <h2 class="mb-2" style="font-weight: 400">{{$t('prices.benefits.2.header')}}</h2>
        <p class="body-2" style="font-weight: 300">{{$t('prices.benefits.2.text')}}</p>
      </v-col>
      <v-col>
        <h2 class="mb-2" style="font-weight: 400">{{$t('prices.benefits.3.header')}}</h2>
        <p class="body-2" style="font-weight: 300">{{$t('prices.benefits.3.text')}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PricesBenefits"
}
</script>

<style scoped>

</style>